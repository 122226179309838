import Link from "next/link";

import { useSelector } from "redux/hooks";
import { getAreCategoryCoinIconsHidden } from "redux/selectors/complianceRules";

import IconSvg from "components/IconSvg";

const Coin = ({
  dataTestId,
  href,
  iconFilePath,
  onClick,
  title,
}: {
  dataTestId: string;
  href: string;
  iconFilePath: string;
  onClick: () => void;
  title: string;
}) => {
  const hideCategoryIcons = useSelector(getAreCategoryCoinIconsHidden);

  return (
    <li className="flex-shrink-0 mb-2 md:mb-none min-w-20 w-20">
      <Link
        href={href}
        onClick={onClick}
        className="flex border border-grey-44 flex-col items-center justify-center h-[80px] bg-green-20 rounded-[8px]"
        data-testid={dataTestId}
      >
        {!hideCategoryIcons ? (
          <div className="flex justify-center items-center w-[50px] h-[50px] text-white">
            <IconSvg filePath={iconFilePath} width="50" height="50" />
          </div>
        ) : null}
        <span className="text-xs">{title}</span>
      </Link>
    </li>
  );
};

export default Coin;
