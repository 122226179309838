import React, { createContext, ReactNode, useMemo } from "react";
import { useRouter } from "next/router";

import getMenuItems, {
  DEFAULT_TAKE_COUNT,
  GetMenuItemsReturn,
} from "api/requests/getMenuItems";
import { CATEGORY_NAMES } from "constants/dispensary";
import { MenuItem } from "custom-types/MenuItem";
import usePromiseReducer from "hooks/usePromiseReducer";
import { useSelector } from "redux/hooks";
import { getUserPrefersMedical } from "redux/selectors/user";
import { nestedFilterValuesByName } from "utils/filterUtils";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

export type MenuContextState = GetMenuItemsReturn & {
  categories: string[];
  loading: boolean;
  staffPicks: MenuItem[];
  totalPages: number;
};

export const initialState: MenuContextState = {
  availableFilters: [],
  availableSorts: [],
  categories: [],
  loading: false,
  menuItems: [],
  staffPicks: [],
  supportedFilters: [],
  totalItems: 0,
  totalPages: 0,
};

export const initialContext = {
  dispatch: {
    getMenuItems: () => Promise.resolve(),
    getStaffPicks: () => Promise.resolve(),
  },
  selectors: {
    availableFilters: initialState.availableFilters,
    availableSorts: initialState.availableSorts,
    categories: initialState.categories,
    loading: initialState.loading,
    menuItems: initialState.menuItems,
    staffPicks: initialState.staffPicks,
    totalItems: initialState.totalItems,
    totalPages: initialState.totalPages,
  },
};

const Context = createContext(initialContext);
const { Provider } = Context;

const ACTION_TYPES = {
  LOADING: "LOADING",
  RESULTS: "RESULTS",
  STAFF_PICKS: "STAFF_PICKS",
};

const useMenuReducer = (state: MenuContextState) => {
  return usePromiseReducer(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    (state: MenuContextState, action: any) => {
      switch (action.type) {
        case ACTION_TYPES.LOADING:
          return { ...state, ...action.payload };
        case ACTION_TYPES.RESULTS:
          return { ...state, ...action.payload };
        case ACTION_TYPES.STAFF_PICKS:
          return { ...state, ...action.payload };
        default:
          return state;
      }
    },
    { ...initialState, ...(state || {}) },
  );
};

const MenuProvider = ({
  children,
  initialData,
  isDualLicenseDispensary,
  staffPicks = [],
}: {
  children: ReactNode;
  isDualLicenseDispensary: boolean;
  initialData?: GetMenuItemsReturn;
  staffPicks?: MenuItem[];
}) => {
  const {
    query: { slug },
    asPath,
  } = useRouter();
  const { query: parsedQuery } = parseNextRouterAsPath(asPath);

  const userPrefersMedical = useSelector(getUserPrefersMedical);

  /** Reducer **/
  const [state, dispatch] = useMenuReducer({
    ...initialState,
    ...initialData,
    staffPicks,
  });

  /** Actions **/
  const loadingAction = () =>
    dispatch({
      payload: {
        loading: true,
      },
      type: ACTION_TYPES.LOADING,
    });

  const getMenuItemsAction = async () => {
    loadingAction();
    const menuQuery = {
      ...parsedQuery,
      enableNewFilters: true,
      ...(isDualLicenseDispensary
        ? { menu_type: userPrefersMedical ? "Med" : "Rec" }
        : {}),
    };

    const response = await getMenuItems(
      slug as string,
      menuQuery,
      state.supportedFilters.reduce(
        (supportedMap, filterName) => ({
          [filterName]: true,
          ...supportedMap,
        }),
        {},
      ),
    );

    dispatch({
      payload: {
        loading: false,
        ...response,
      },
      type: ACTION_TYPES.RESULTS,
    });
  };

  const getStaffPicksAction = async () => {
    loadingAction();

    const menuQuery = { ...parsedQuery };
    if (isDualLicenseDispensary) {
      menuQuery.menu_type = userPrefersMedical ? "Med" : "Rec";
    }

    const response = await getMenuItems(slug as string, {
      is_staff_pick: true,
      ...menuQuery,
    });

    dispatch({
      payload: {
        loading: false,
        staffPicks: response.menuItems,
      },
      type: ACTION_TYPES.STAFF_PICKS,
    });
  };

  /** Selectors **/
  const selectMenuItems = useMemo(() => state.menuItems || [], [state]);

  const selectStaffPicks = useMemo(
    () =>
      state.staffPicks?.sort((a: MenuItem, b: MenuItem) => {
        if (a.staffPickOrder !== null && b.staffPickOrder !== null) {
          return a.staffPickOrder - b.staffPickOrder;
        }
        return 0;
      }) || [],
    [state],
  );

  const selectTotalItems = useMemo(() => state.totalItems || 0, [state]);

  const selectLoading = useMemo(() => state.loading || false, [state]);

  const selectAvailableFilters = useMemo(
    () => state.availableFilters || [],
    [state],
  );

  const selectAvailableSorts = useMemo(
    () => state.availableSorts || [],
    [state],
  );

  const selectCategories = useMemo(() => {
    const categories =
      nestedFilterValuesByName(state.availableFilters, "product_category") ||
      [];

    const popularCategories = Object.keys(CATEGORY_NAMES).reduce(
      (list: string[], categoryName) => {
        const matchingCategory = categories?.find(
          (name) => categoryName === name,
        );
        return matchingCategory ? [...list, matchingCategory] : list;
      },
      [],
    );

    const otherCategories =
      categories?.filter(
        (name) => !Object.keys(CATEGORY_NAMES).includes(name),
      ) || [];

    return [...popularCategories, ...otherCategories];
  }, [JSON.stringify(state.availableFilters)]);

  const selectTotalPages = useMemo(
    () => Math.ceil((state.totalItems || 0) / DEFAULT_TAKE_COUNT),
    [state.totalItems],
  );

  return (
    <Provider
      value={{
        dispatch: {
          getMenuItems: getMenuItemsAction,
          getStaffPicks: getStaffPicksAction,
        },
        selectors: {
          availableFilters: selectAvailableFilters,
          availableSorts: selectAvailableSorts,
          categories: selectCategories,
          loading: selectLoading,
          menuItems: selectMenuItems,
          staffPicks: selectStaffPicks,
          totalItems: selectTotalItems,
          totalPages: selectTotalPages,
        },
      }}
    >
      {children}
    </Provider>
  );
};

export { Context as default, MenuProvider };
